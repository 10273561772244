<template>
  <div>
    <div style="position: relative;">
      <img src="@/image/MT1_03.jpg" style="width: 100%;">
      <div class="mtparice flex_center">{{$t('mt.title')}}{{price}}USDT</div>
    </div>
    <!-- 段落1 -->
    <div class="part1">
      <div class="title" style="line-height: normal;margin-bottom:5px;">{{$t('mt.title1')}}</div>
      <div class="text common_text">{{$t('mt.text1')}}</div>
    </div>
    <!-- 段落2 -->
    <div class="part2">
      <div class="flex_lr" style="margin-bottom: 10px;">
        <img src="@/image/MT1_06.jpg" style="width: 40%">
        <div class="title" style="font-size: calc(var(--title) - 3px)">{{$t('mt.title2')}}</div>
      </div>
      <div class="text common_text">{{$t('mt.text2')}}</div>
    </div>
    <!-- 段落3 -->
    <img src="@/image/MT1_09.jpg" style="width: 100%">
    <!-- <div class="part3">
      <div class="title" style="line-height: normal;">{{$t('mt.title3')}}</div>
      <div class="text common_text" style="margin-top: 10px;">{{$t('mt.text3')}}</div>
    </div> -->
    <!-- 段落4 -->
    <!-- <img src="@/image/MT_09.jpg" style="width: 100%"> -->
    <div class="part4">
      <div class="title" style="line-height: normal;">{{$t('mt.title4')}}</div>
      <div class="text common_text" style="margin-top: 10px;">{{$t('mt.text4')}}</div>
    </div>
    <!-- 段落5 -->
    <!-- <img src="@/image/MT_11.jpg" style="width: 100%"> -->
    <!-- <img src="@/image/MT_12.jpg" style="width: 100%"> -->
    <!-- <div class="part5">
      <div class="title" style="line-height: normal;">{{$t('mt.title5')}}</div>
      <div class="title common_text" style="font-size: calc(var(--text) + 3px)">{{$t('mt.bonus_title')}}</div>
      <div class="bonus flex_row common_text" v-for="(item,index) in $t('mt.bonus')" :key="index">
        <div class="bonus_title">{{item.title}}</div>
        <div class="bonus_text">{{item.text}}</div>
      </div>
      <div class="remarks common_text" style="font-size: calc(var(--text) - 3px)">{{$t('mt.remarks')}}</div>
    </div> -->
  </div>
</template>

<script>
import walletHelper from "@/utils/walletHelper.js"
export default {
  name: "index",
  components: {},
  data() {
    return {
      nType:'mt',
      price:0
    }
  },
  async mounted() {
    await this.$onLaunched;
    this.init();
  },
  methods: {
    init() {
      const that=this
      try{
       walletHelper.getContract('query').methods.getTokenToUsdt(walletHelper.getContractAddress(that.nType),walletHelper.toWei("1")).call().then((result)=>{
        console.log('result',result)
        this.price = walletHelper.Wei(result)
      })
      }catch(e){
        console.log(e)
     
      }
    },
    
  },
};
</script>
<style lang="scss" scoped>
.text{
  padding: 0 15px;
  color: #ffffff;
}
.common_text{
  font-size: calc(var(--text) - 2px);
}
.title{
  padding: 0 15px;
  font-size: calc(var(--title) - 2px);
  color: #1CC6BC;
}
.mtparice{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 0 15px;
  width: 96%;
  // background-color: #E3DEB4;
  background: linear-gradient(to right,rgba(0, 0, 0, 0),rgba(38, 141, 40, 0.8) 10% 90%,rgba(0, 0, 0, 0));
  // border-radius: 10px;
  height: 40px;
  color: #fff;
  font-size: calc(var(--title) - 2px);
}
.part1{
  padding: 5px 0;
  // background: #7E6312;
  position: relative;
}
.part2{
  padding: 10px 0;
  // background: #ffffff;
  .title{
    color: #1CC6BC;
    // font-weight: bold;
  }
}
.part3{
  // background: #ff0000;
  padding: 10px 0;
}
.part4{
  // background: #1A7917;
  padding: 10px 0;
}
.part5{
  // background: #000000;
  padding: 10px 0;
  .title{
    color: #ff0000;
    line-height: 20px;
    font-weight: bold;
    padding: 0 15px;
    margin-top: 5px;
  }
  .bonus{
    padding: 3px 15px;
    align-items: flex-start;
    .bonus_title{
      color: #ff0000;
      width: 30%;
    }
    .bonus_text{
      color: #ffffff;
      width: 0;
      flex: 1;
    }
  }
  .remarks{
    padding: 5px 15px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #00FFEA;
  }
}
</style>